<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="40%"
    >
      <el-form :model="addEditForm" label-width="120px" ref="addEditForm">
        <el-form-item label="绑定产品" prop="roleName">
          <el-select
            v-model="addEditForm.uniqueCode"
            placeholder="请选择"
            :disabled="editShow"
            @change="getUniqueCodeButton"
          >
            <el-option
              v-for="item in uniqueCodeList"
              :key="item.uniqueCode"
              :label="item.name"
              :value="item.uniqueCode"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="角色名称" prop="roleName">
          <el-input
            placeholder="请输入角色名称"
            v-model="addEditForm.roleName"
            clearable
            @clear="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode" v-if="!editShow">
          <el-input
            placeholder="请输入角色编码"
            v-model="addEditForm.roleCode"
            clearable
            @clear="handleQuery()"
          >
            <template slot="prepend">{{this.prefixCode}}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode" v-else>
          <el-input
            placeholder="请输入角色编码"
            v-model="addEditForm.roleCode"
            disabled
            clearable
            @clear="handleQuery()"
          ></el-input>
        </el-form-item>

        <el-form-item label="角色描述" prop="roleDescribe">
          <el-input
            placeholder="请输入角色描述"
            v-model="addEditForm.roleDescribe"
            clearable
            @clear="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="顺序" prop="roleSort">
          <el-input-number
            placeholder="请输入顺序"
            v-model="addEditForm.roleSort"
            clearable
            @clear="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="权限字符" prop="perms">
          <el-input
            placeholder="请输入权限字符"
            v-model="addEditForm.perms"
            clearable
            @clear="handleQuery()"
            disabled
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio v-model="addEditForm.status" label="1">正常</el-radio>
          <el-radio v-model="addEditForm.status" label="2">停用</el-radio>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            placeholder="请输入备注"
            v-model="addEditForm.remark"
            clearable
            @clear="handleQuery()"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="addFormButton()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import {
  selectroleByIdApi,
  deleteroleByIdApi,
  editroleApi,
  addroleApi,
  listroleApi
} from "@/api/system/role/role.js";
import { listDataProductApi } from "@/api/system/product/product.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {},
      timer: null,
      optType: 1,
      uniqueCodeList: [],
      prefixCode: ""
    };
  },
  methods: {
    show(id, type) {
      this.dialog = true;
      this.initUniqueCodeList();
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
      this.optType = type;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      this.addEditForm.roleCode = this.prefixCode + this.addEditForm.roleCode;
      if (this.addEditForm.id == undefined || this.addEditForm.id == null) {
        this.addEditForm.id;
        addroleApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      } else {
        editroleApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton(id) {
      selectroleByIdApi(id).then(res => {
        this.addEditForm = res.result;
      });
    },
    getUniqueCodeButton(value) {
      console.log("res", value);
      this.prefixCode = value + "-";
    },
    initUniqueCodeList() {
      listDataProductApi({ attribute: 1 }).then(res => {
        this.uniqueCodeList = res.result;
      });
    }
  }
};
</script>
<style scoped>
</style>



